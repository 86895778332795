// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
}

.image-container img {
    width: 100%;
}

.image-container .actions {
    display: block;
    position: relative;
    width: 100%;
    text-align: right;
    padding-right: 18px;
}`, "",{"version":3,"sources":["webpack://./src/css/ImageView.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".image-container {\n    display: block;\n    position: relative;\n    box-sizing: border-box;\n    width: 100%;\n    text-align: center;\n}\n\n.image-container img {\n    width: 100%;\n}\n\n.image-container .actions {\n    display: block;\n    position: relative;\n    width: 100%;\n    text-align: right;\n    padding-right: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
