// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-filter-container {
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 1px 15px 1px 15px;
}

.search-error {
    position: relative;
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
}

.search-error i {
    display: block;
    color: var(--color-font-error-large);
    font-size: 72px;
    font-weight: bold;
    padding: 50px 10px 10px 10px;
}`, "",{"version":3,"sources":["webpack://./src/css/OrderSearch.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,oCAAoC;IACpC,eAAe;IACf,iBAAiB;IACjB,4BAA4B;AAChC","sourcesContent":[".order-filter-container {\n    display: block;\n    position: relative;\n    box-sizing: border-box;\n    width: 100%;\n    padding: 1px 15px 1px 15px;\n}\n\n.search-error {\n    position: relative;\n    display: block;\n    margin: 0 auto;\n    text-align: center;\n    font-size: 20px;\n}\n\n.search-error i {\n    display: block;\n    color: var(--color-font-error-large);\n    font-size: 72px;\n    font-weight: bold;\n    padding: 50px 10px 10px 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
