// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rating {
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 100%;
}

.rating .rating-button {
    display: inline-block;
    box-sizing: border-box;
    width: 10%;
}`, "",{"version":3,"sources":["webpack://./src/css/Rating.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,UAAU;AACd","sourcesContent":[".rating {\n    display: block;\n    position: relative;\n    box-sizing: border-box;\n    width: 100%;\n}\n\n.rating .rating-button {\n    display: inline-block;\n    box-sizing: border-box;\n    width: 10%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
