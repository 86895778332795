// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spirits-actual {
    display: block;
}

.spirits-actual .spirits-actual-title, .spirits-actual .title-actions {
    display: inline-block;
    width: 50%;
}

.spirits-actual .spirits-actual-title {
    font-weight: bold;
}

.spirits-actual .title-actions {
    text-align: right;
}

.spirits-actual-actions {
    text-align: right;
    padding-top: 4px;
    width: 100%;
}

.spirits-actual-actions button, .spirits-actual .title-actions button {
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/css/SpiritsActual.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".spirits-actual {\n    display: block;\n}\n\n.spirits-actual .spirits-actual-title, .spirits-actual .title-actions {\n    display: inline-block;\n    width: 50%;\n}\n\n.spirits-actual .spirits-actual-title {\n    font-weight: bold;\n}\n\n.spirits-actual .title-actions {\n    text-align: right;\n}\n\n.spirits-actual-actions {\n    text-align: right;\n    padding-top: 4px;\n    width: 100%;\n}\n\n.spirits-actual-actions button, .spirits-actual .title-actions button {\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
