// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 0px 20px 20px 20px;
}

.pagination .control {
    position: relative;
    display: inline-block;
    flex: 1 1;
}

.pagination .info {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 8px 0px 8px 0px;
}

.pagination .info span {
    padding: 0px;
    color: var(--color-font-300);
}

.pagination button {
    width: 38px;
    background-color: var(--color-surface-200);
    border-radius: 5px;
    padding: 4px;
    font-size: 20px;
    color: var(--color-font-400);
    margin: 4px;
}

.pagination button.current {
    background-color: var(--color-primary-200);
    color: var(--color-font-header);
}

.pagination button.disabled {
    background-color: var(--color-surface-200);
    color: var(--color-font-100);
}`, "",{"version":3,"sources":["webpack://./src/css/Pagination.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,SAAO;AACX;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,sBAAsB;IACtB,wBAAwB;AAC5B;;AAEA;IACI,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,0CAA0C;IAC1C,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,4BAA4B;IAC5B,WAAW;AACf;;AAEA;IACI,0CAA0C;IAC1C,+BAA+B;AACnC;;AAEA;IACI,0CAA0C;IAC1C,4BAA4B;AAChC","sourcesContent":[".pagination {\n    position: relative;\n    display: flex;\n    box-sizing: border-box;\n    width: 100%;\n    padding: 0px 20px 20px 20px;\n}\n\n.pagination .control {\n    position: relative;\n    display: inline-block;\n    flex: 1;\n}\n\n.pagination .info {\n    position: relative;\n    display: inline-block;\n    box-sizing: border-box;\n    padding: 8px 0px 8px 0px;\n}\n\n.pagination .info span {\n    padding: 0px;\n    color: var(--color-font-300);\n}\n\n.pagination button {\n    width: 38px;\n    background-color: var(--color-surface-200);\n    border-radius: 5px;\n    padding: 4px;\n    font-size: 20px;\n    color: var(--color-font-400);\n    margin: 4px;\n}\n\n.pagination button.current {\n    background-color: var(--color-primary-200);\n    color: var(--color-font-header);\n}\n\n.pagination button.disabled {\n    background-color: var(--color-surface-200);\n    color: var(--color-font-100);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
