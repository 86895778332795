// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recipe-list-empty {
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 30px 20px 10px 20px;
    font-size: 32px;
    font-family: 'Courier New', Courier, monospace;
}`, "",{"version":3,"sources":["webpack://./src/css/RecipeList.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,4BAA4B;IAC5B,eAAe;IACf,8CAA8C;AAClD","sourcesContent":[".recipe-list-empty {\n    display: block;\n    position: relative;\n    box-sizing: border-box;\n    width: 100%;\n    padding: 30px 20px 10px 20px;\n    font-size: 32px;\n    font-family: 'Courier New', Courier, monospace;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
