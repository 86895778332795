// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    position: fixed;
    box-sizing: border-box;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 0px;
    background-color: rgba(100, 100, 100, 0.5);
    z-index: 2;
}

.modal .body {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: var(--color-surface-100);
    opacity: 1.0;
    overflow: auto;
}

.show {
    display: block;
}

.hide {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/css/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,sBAAsB;IACtB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,0CAA0C;IAC1C,UAAU;AACd;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".modal {\n    position: fixed;\n    box-sizing: border-box;\n    top: 0px;\n    left: 0px;\n    width: 100%;\n    height: 100%;\n    padding: 0px;\n    background-color: rgba(100, 100, 100, 0.5);\n    z-index: 2;\n}\n\n.modal .body {\n    display: block;\n    box-sizing: border-box;\n    width: 100%;\n    height: 100%;\n    background-color: var(--color-surface-100);\n    opacity: 1.0;\n    overflow: auto;\n}\n\n.show {\n    display: block;\n}\n\n.hide {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
