// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-update {
    margin-bottom: 6px;
}

.order .order-update-text textarea {
    box-sizing: border-box;
    width: 100%;
    height: 68px;
}

.order .order-update-text input {
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
}

.order .order-update-text .ta-container {
    margin: 2px 0px 0px 0px;
}

.order .order-complete {
    padding-top: 4px;
    display:flex;
    align-items: left;
    justify-content:left;
}

.order .order-complete .label {
    display: inline-block;
    vertical-align: middle;
}

.order .order-complete .label:first-child {
    padding-bottom: 6px;
}

.order .order-update-actions-delete {
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    padding: 16px 0px 0px 0px;
    text-align: left;
}

.order .order-update-actions {
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    padding: 16px 0px 0px 0px;
    text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/css/OrderUpdate.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,UAAU;IACV,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,UAAU;IACV,yBAAyB;IACzB,iBAAiB;AACrB","sourcesContent":[".order-update {\n    margin-bottom: 6px;\n}\n\n.order .order-update-text textarea {\n    box-sizing: border-box;\n    width: 100%;\n    height: 68px;\n}\n\n.order .order-update-text input {\n    box-sizing: border-box;\n    width: 100%;\n    padding: 4px;\n}\n\n.order .order-update-text .ta-container {\n    margin: 2px 0px 0px 0px;\n}\n\n.order .order-complete {\n    padding-top: 4px;\n    display:flex;\n    align-items: left;\n    justify-content:left;\n}\n\n.order .order-complete .label {\n    display: inline-block;\n    vertical-align: middle;\n}\n\n.order .order-complete .label:first-child {\n    padding-bottom: 6px;\n}\n\n.order .order-update-actions-delete {\n    display: inline-block;\n    box-sizing: border-box;\n    width: 50%;\n    padding: 16px 0px 0px 0px;\n    text-align: left;\n}\n\n.order .order-update-actions {\n    display: inline-block;\n    box-sizing: border-box;\n    width: 50%;\n    padding: 16px 0px 0px 0px;\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
